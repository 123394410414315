<template>
  <AppHeader />
  <br>
    <Suspense>
      <template #default>
        <Games />
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import { defineAsyncComponent, Suspense } from "vue";
const Games = defineAsyncComponent(() =>
  import('@/components/Pages/Games.vue')
)
export default {
  name: "Game",
  components: {
    AppHeader,
    AppFooter,
    Games,
    Suspense,
    Spinner
  },
};
</script>

<style>
</style>