<template>
  <!-- footer area start -->
    <section class="py-4 osahan-copyright" style=" padding-top: 2.5rem !important;background-image: linear-gradient(0deg, #481582 0, #70499D) !important;">
    <div class="container">
        <div class="row" dir="rtl">
            
            
            
            <div class="col-xl-12">
                <ul class="navbar-nav menu-open text-end align-items-center">
                    
                    <li>
                    <p class="m-0">
                        <router-link to="/" href="https://sd.zain.com/" class="text-secondary"><img style="width: 130px;" src="../../assets/zain_gray.png" alt="">
                        </router-link>

                    </p>
                    </li>
                    <li style="margin-top: 10px;">
                    <p class="m-0 text-muted" style="color: #e5dbe8 !important;">Powered<i class="feather-heart text-danger"></i> by <a class="text-dark" target="_blank" href="http://www.byte.sd">Telesis</a></p>
                    </li>
                    <li style="margin-top: 10px;">
                    <p class="m-0 text-muted" style="color: #e5dbe8 !important;">Copyright ©  2024 | Designed  <i class="feather-heart text-danger"></i> by <a class="text-dark" target="_blank" href="http://www.byte.sd">Byte</a></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
    <!-- footer area end -->

    <!-- back to top area start -->
    <!-- <div class="back-to-top">
        <span class="back-top"><i class="fa fa-angle-up"></i></span>
    </div> -->
    <!-- back to top area end -->

</template>

<script>
export default {
   name: 'AppFooter',
    
}
</script>

<style>
@media (min-width:1440px){
  .footer-logo {
    width: 10%;
}

}
@media (min-width:1200px){
  .footer-logo {
    width: 10%;
    margin-right: 150px;
}

}
@media (min-width:900px){
  .footer-logo {
    width: 10%;
    margin-right: 150px;
}

}
@media (min-width:768px){
  .footer-logo {
    width: 15%;
    margin-right: 450px;
    /* padding-top: 10px;
    padding-bottom: 20px; */
}
}
@media (min-width:414px){
  .footer-logo {
    width: 15%;
    margin-right: 130px;
    padding-top: 10px;
    padding-bottom: 20px;
}
}
@media (width:360px){
  .footer-logo {
    width: 30%;
    margin-right: 130px;
    padding-top: 10px;
    padding-bottom: 20px;
}

}
</style>