<template>
    <!-- search popup start-->
    <div class="body-overlay" id="body-overlay"></div>                                
    <div class="td-search-popup" id="td-search-popup">
        <form  class="search-form" @submit.prevent="SearchData()">
            <div class="form-group">
                <input type="text" v-model="search" class="form-control arabic-kufi" placeholder="بحث.....">
            </div>
            <button type="submit" class="submit-btn"><i class="fa fa-search"></i></button>
        </form>
    </div>
    <!-- search popup end-->
    <!-- navbar start -->
    <div class="navbar-area">
        <nav class="navbar navbar-area-2 navbar-expand-lg">
            <div class="container nav-container">
                <div class="responsive-mobile-menu">
                    <button class="menu toggle-btn d-block d-lg-none" data-target="#edumint_main_menu" 
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="icon-left"></span>
                        <span class="icon-right"></span>
                    </button>
                </div>
                <div class="logo">
                    <router-link to="/"><img src="../../assets/buzz.png" alt="TBuss"></router-link>
                    <!-- <router-link to="/"><h3 class="title arabic-kufi " style="color: #fff;">Buzz</h3></router-link> -->
                </div>
                <!-- <div class="nav-right-part nav-right-part-mobile">
                    <a class="search-bar-btn" href="#"><i class="fa fa-search"></i></a>
                </div> -->
                <div class="nav-right-part nav-right-part-mobile" >
                    <template v-if="showHeader">
                        <router-link to="/unsubscribe" class="headers-btn"> الغاء الاشتراك</router-link>
                    </template>
                    <template v-else>
                        <a @click="Subscrib()" class="headers-btn" >إشتــراك</a>
                    </template>
                    <img class="zain-logo"  src="../../assets/zain_gray.png" />
                </div>
                <div class="collapse navbar-collapse" id="edumint_main_menu">
                    <ul class="navbar-nav menu-open text-end">
                        <li class="arabic-kufi"><router-link to="/">الصفحة الرئيسية</router-link></li>
                        <li class="arabic-kufi"><router-link to="/Game">العاب</router-link></li>
                        <li class="arabic-kufi"><router-link to="/Islamyat">أسلاميات</router-link></li>
                        <li class="arabic-kufi"><router-link to="/Kitchen" >المطبخ</router-link></li>
                        <li class="arabic-kufi"><router-link to="/Kids">اطفال</router-link></li>
                        <template v-if="showHeader">
                        <li class="menu-item-has-children current-menu-item" dir="ltr">
                            <!-- <a href="#">{{ $cookie.getCookie("msisdn") }}</a> -->
                        <!-- <ul class="sub-menu" dir="ltr">
                            <li>
                                <a @click="logOut" class="arabic-kufi pointer">  تسجيل خروج</a>
                            </li>
                        </ul> -->
                        </li>
                        </template>
                        <template v-else>
                        <li><router-link to="/Login">تسجيل الدخول</router-link></li>
                        <!-- <li class="menu-item-has-children current-menu-item" dir="ltr"><a href="#">الحساب</a>
                            <ul class="sub-menu" dir="ltr">
                                <li>
                                    <router-link to="/Login" >تسجيل الدخول</router-link>
                                </li>
                            </ul>
                        </li> -->
                       </template>
                    </ul>
                </div>
                <div class="nav-right-part nav-right-part-desktop">
                    <a class="search-bar-btn" href="" style="color: #fff;"><i class="fa fa-search"></i></a>
                </div>
                <div class="nav-right-part nav-right-part-desktop">
                    <template v-if="showHeader">
                        <router-link to="/unsubscribe" class="headers-btn"> الغاء الاشتراك</router-link>
                    </template>
                    <template v-else>
                        <a @click="Subscrib()" class="headers-btn">إشتـرك الان</a>
                    </template>
                    <img class="zain-logo" src="../../assets/zain_gray.png" />
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next';
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
export default {
    name: 'AppHeader',
    data() {
    return {
        search: ""
      };
    },
    setup() {
      const cookie = useCookie()
      const toast = useToast();
      const router = useRouter();
      const route = useRoute();
      const showHeader = ref(true);
    
    watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                        // cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        }); 

        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=2887461945", "_self");
        }

        return { showHeader , Subscrib}

    },
    mounted() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s. src = '/assets/js/main.js';
        document.body.appendChild(s);
    },
    methods: {
        SearchData() {
           this.$router.push({ name: "Search", params: { search_id: this.search } });
        }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.logo {
    width: 10%;
}

@media (max-width:428px){
    .logo {
       width: 25%;
    }
}
@media (max-width:360px){
    .logo {
       width: 25%;
    }
}
</style>